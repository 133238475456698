import Vue from 'vue'

/** Used to invalidate cached/stored items like when the language of the site changes */
function ResetStores(vue: Vue) {
  // TODO: Add more stores to this list
  vue.$store.commit('filters/RESET')
  vue.$store.commit('sermons/RESET')
}

export function RefreshPage(vue: Vue) {
  ResetStores(vue)
  // We shouldn't need to do a refresh, but just in case:
  // await vue.$nuxt.refresh()
}
