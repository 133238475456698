
import Vue from 'vue'

export default Vue.extend({
  name: 'LanguageIcon',
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  computed: {
    displayCode(): string {
      return this.code.split('-')[0]
    },
  },
})
