
import Vue from 'vue'
import IndicatorBubble from '~/components/_general/IndicatorBubble.vue'
import { UserMessages } from '~/models/users/user'

export default Vue.extend({
  name: 'SiteLaunchDropdownIndicator',
  components: { IndicatorBubble },
  computed: {
    // TODO: remove this indicator one day
    show(): boolean {
      if (this.$device.isMobile) return false
      return this.$users.canShowMessage(UserMessages.LegacyDropdown)
    },
  },
  methods: {
    dismiss() {
      this.$users.messageShown(UserMessages.LegacyDropdown)
    },
  },
})
