
import Vue, { PropType } from 'vue'
import { User } from '~/models/users/user'
import { DisplayUser } from '~/models/usercomment'
import SiteUserProfileIcon from '~/components/site/user/ProfileIcon.vue'

export default Vue.extend({
  name: 'SiteUserDisplay',
  components: { SiteUserProfileIcon },
  props: {
    showRoles: {
      type: Boolean,
    },
    largeName: {
      type: Boolean,
      default: true,
    },
    displayUser: {
      type: Object as PropType<DisplayUser>,
      default: undefined,
    },
  },
  computed: {
    displayName(): string {
      return this.displayUser?.displayName ?? this.user?.displayName ?? ''
    },
    username(): string {
      return this.displayUser?.username ?? this.user?.username ?? ''
    },
    centerName(): boolean {
      if (this.displayUser) {
        return !this.displayUser.displayName
      }
      if (!this.user || this.user.displayName) return false
      return !this.showRoles || this.user.broadcasterRoles.length === 0
    },
    user(): User | undefined {
      if (this.displayUser) return undefined
      return this.$users.user
    },
  },
})
