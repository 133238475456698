
import Vue, { PropType } from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'IndicatorBubble',
  components: { SaIcon },
  props: {
    arrowSide: {
      type: String as PropType<'right' | 'left' | 'top' | 'bottom'>,
      default: 'right',
    },
  },
})
