
import Vue from 'vue'
import { LocaleObject } from '@nuxtjs/i18n'
import { TranslateResult } from 'vue-i18n'
import LanguageIcon from '~/components/language/Icon.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { RefreshPage } from '~/assets/ts/utils/page'

interface Language {
  code: string
  localName: string
  translatedName: TranslateResult
}

export default Vue.extend({
  name: 'LanguageSelector',
  components: { SaIcon, LanguageIcon },
  computed: {
    current(): string {
      return this.$i18n.locale
    },
    languages(): Language[] {
      const languages = this.$i18n.locales as LocaleObject[]
      const list = [] as Language[]
      for (let i = 0; i < languages.length; i++) {
        const language = languages[i]
        const code = language.code
        list.push({
          code,
          localName: language.name,
          translatedName: this.getTranslatedLanguage(code),
        })
      }
      return list
    },
  },
  methods: {
    setLanguage(language: Language) {
      this.$i18n.setLocale(language.code).then(() => {
        RefreshPage(this)
      })
    },
    getTranslatedLanguage(code: string): TranslateResult {
      switch (code) {
        case 'en': {
          return this.$t('English (US)')
        }
        case 'gb': {
          return this.$t('English (UK)')
        }
        case 'zh': {
          return this.$t('Chinese (Simplified)')
        }
        case 'de': {
          return this.$t('German')
        }
        case 'it': {
          return this.$t('Italian')
        }
        case 'ko': {
          return this.$t('Korean')
        }
        case 'pt': {
          return this.$t('Portuguese')
        }
        case 'es': {
          return this.$t('Spanish')
        }
        case 'km': {
          return this.$t('Khmer')
        }
        case 'fr': {
          return this.$t('French')
        }
        case 'hi': {
          return this.$t('Hindi')
        }
        case 'ta': {
          return this.$t('Tamil')
        }
        default: {
          console.error('Invalid choice')
          return ''
        }
      }
    },
  },
})
