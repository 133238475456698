
import Vue from 'vue'
import { User } from '~/models/users/user'
import {
  broadcasterSelectUrl,
  broadcasterSignInUrl,
  siteLoginUrl,
  siteUserPageUrl,
} from '~/assets/ts/utils/urls'
import { sendMessageToIframeParent } from '~/assets/ts/utils/iframe'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import SiteUserProfileIcon from '~/components/site/user/ProfileIcon.vue'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import LanguageSelector from '~/components/language/Selector.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import SiteUserDisplay from '~/components/site/user/Display.vue'
import LanguageIcon from '~/components/language/Icon.vue'
import SiteLaunchDropdownIndicator from '~/components/site/LaunchDropdownIndicator.vue'

export default Vue.extend({
  name: 'SiteUserDropdown',
  components: {
    SiteLaunchDropdownIndicator,
    LanguageIcon,
    SiteUserDisplay,
    HorizontalRule,
    LanguageSelector,
    SaIcon,
    CustomDropdown,
    SiteUserProfileIcon,
  },
  props: {
    dashboard: {
      type: Boolean,
    },
    embed: {
      type: Boolean,
    },
  },
  data() {
    return {
      showTheme: false,
      showLanguage: false,
      containerHeight: 0,
      minContainerHeight: 0,
    }
  },
  computed: {
    signInUrl(): string {
      return siteLoginUrl(this)
    },
    createUserUrl(): string {
      return siteLoginUrl(this, true)
    },
    user(): User | undefined {
      return this.$users.user
    },
    admin(): boolean {
      return !!this.user?.admin
    },
    broadcasterCount(): number {
      if (!this.user) return 0
      return this.user.broadcasterRoles.length
    },
    subpage(): boolean {
      return this.showTheme || this.showLanguage
    },
    containerStyle(): string {
      if (!this.containerHeight) return 'height: auto'
      return `height: ${this.containerHeight}px`
    },
    selectBroadcasterUrl(): string {
      return broadcasterSelectUrl(this.embed ? undefined : this)
    },
    broadcasterSignInUrl(): string {
      return broadcasterSignInUrl
    },
  },
  destroyed() {
    this.$off('close', this.closeModal)
  },
  mounted() {
    this.$on('close', this.closeModal)
  },
  methods: {
    siteUserPageUrl,
    goToDefaultPage() {
      this.showTheme = false
      this.showLanguage = false
      this.groupChanged()
    },
    setLanguageActive() {
      this.showLanguage = true
      this.groupChanged()
    },
    setThemeActive() {
      this.showTheme = true
      this.groupChanged()
    },
    groupChanged() {
      this.$nextTick(() => {
        const container = this.$refs.container as Element | undefined
        if (!container) return
        const child = container.querySelector('.subgroup.active') as Element
        if (!child) {
          this.containerHeight = this.minContainerHeight
          return
        }
        const childHeight = child.clientHeight
        this.containerHeight =
          childHeight > this.minContainerHeight
            ? childHeight
            : this.minContainerHeight
      })
    },
    modalClosed() {
      this.sendMessage('user-modal-open', false)
      setTimeout(() => {
        this.goToDefaultPage()
      }, 150)
    },
    async modalOpened() {
      this.sendMessage('user-modal-open', true)
      this.$emit('opened')
      this.containerHeight = 0
      await waitOneFrame()

      this.minContainerHeight = (this.$refs.container as Element).clientHeight
      this.groupChanged()
    },
    closeModal() {
      const parent = this.$refs.parent as Vue
      parent.$emit('close')
    },
    setTheme(theme: string) {
      this.$colorMode.preference = theme
    },
    async logOut() {
      this.closeModal()
      await this.$users.logOut()
      await this.$store.dispatch('local/setMyChurchBroadcasterID', '')
      if (this.dashboard) {
        this.navigate('/')
      } else {
        this.$router.go(0)
      }
    },
    navigate(url: string) {
      this.closeModal()
      this.sendMessage('navigate', `${location.origin}${url}`)
      if (!this.embed) {
        this.$navigateTo(url)
      }
    },
    sendMessage(event: string, message: any) {
      if (!this.embed) return
      sendMessageToIframeParent(event, message)
    },
  },
})
